import { DownOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Empty,
  Menu,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
} from "antd";

import React from "react";
import Appshell from "Appshell";
import { withTranslation } from "react-i18next";
import { FullFileBrowser, setChonkyDefaults, ChonkyActions } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import { TaskFileService, TaskService } from "../../services";
import {
  s3BucketURL,
} from "../../utils/helpers";
import TaskDetail from "../../scenes/Tasks/TaskEdit";

setChonkyDefaults({ iconComponent: ChonkyIconFA });

class FileManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      task: {},
      taskVisible: false,
      files: [],
    };
  }
  componentDidMount() {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  render() {
    const { visible, employee } = this.state;
    const {
      t,
    } = this.props;
    return (
      <Appshell activeLink={["", "media-gallery"]}>
        <div className="tw-flex tw-mb-5 tw-justify-between tw-items-center t-users">
          <span className="s-page-title">
            {t("fileManager.pageTitle")}
          </span>
        </div>

        <div>
          <iframe src="https://kpm-file.inverizo.com/elfinder.html" width={'100%'} height={700} />
        </div>
      </Appshell>
    );
  }
}

export default withTranslation()(FileManager);
