import { DownOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Empty,
  Menu,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
} from "antd";

import React from "react";
import Appshell from "Appshell";
import { withTranslation } from "react-i18next";
import { FullFileBrowser, setChonkyDefaults, ChonkyActions } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import { TaskFileService, TaskService } from "../../../services";
import {
  s3BucketURL,
} from "../../../utils/helpers";
import TaskDetail from "../../../scenes/Tasks/TaskEdit";

setChonkyDefaults({ iconComponent: ChonkyIconFA });

class MediaList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      task: {},
      taskVisible: false,
      files: [],
    };
  }
  componentDidMount() {
    TaskFileService.find({
      query: { $limit: 10000 },
    }).then(
      (res) => {
        if (res.data.length) {
          console.log(res.data)
          this.setState({
            files: res.data.map(i => {
              if (i.task) {
                return {
                  id: i._id,
                  name: i.task.customer ? `${i.task.customer.contactPerson} - ${i.task._title}` : i.task._title,
                  thumbnailUrl: `https://karaca-product-images-uploads.s3-eu-central-1.amazonaws.com/${i.url}`,
                  taskId: i.taskId,
                  openable: true,
                  modDate: i.createdAt,
                };
              }
            }),
          });
        }
      },
      (error) => { },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  render() {
    const { visible, employee } = this.state;
    const {
      t,
    } = this.props;

    const folderChain = [{ id: 'xcv', name: 'Root', isDir: true }];

    const myFileActions = [
    ];

    const onFile = (e) => {
      if (e.id == "mouse_click_file") {
        TaskService.get(e.payload.file.taskId).then(
          (res) => {
            this.setState({ taskVisible: true, task: res });
          },
        );
      }
    }

    return (
      <Appshell activeLink={["", "media-gallery"]}>
        <div className="tw-flex tw-mb-5 tw-justify-between tw-items-center t-users">
          <span className="s-page-title">
            {t("mediaGallery.pageTitle")}
          </span>
        </div>

        <div>
          <div style={{ height: 400 }}>
            <FullFileBrowser
              files={this.state.files}
              folderChain={folderChain}
              fileActions={myFileActions}
              onFileAction={onFile}
              disableDefaultFileActions={['downloadFileAction', 'deleteFileAction', 'delete']}
              disableSelection
              disableDragAndDrop
            />
          </div>

          <TaskDetail
            type="task"
            onClose={() => this.setState({ taskVisible: false, task: {} })}
            visible={this.state.taskVisible}
            task={this.state.task}
          />

        </div>
      </Appshell>
    );
  }
}

export default withTranslation()(MediaList);
